import { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

const docURL = process.env.REACT_APP_DOCUMENTATION_URL || '';

const Documentation = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const { pathname } = useLocation();

  const iframeSrc = useMemo(() => {
    if (pathname !== '/') {
      const splitUri = pathname.split('/');
      return `${docURL}/ohjeet/${splitUri[1]}`;
    } else {
      return `${docURL}/ohjeet/etusivu`;
    }
  }, [pathname]);

  return (
    <div className={`documentation-modal ${modalOpen ? 'show' : 'hide'}`}>
      <div className={`documentation-button ${modalOpen ? 'show' : 'hide'}`} onClick={() => setModalOpen(!modalOpen)}>
        {modalOpen ? <FormattedMessage id='text_close' /> : <FormattedMessage id='text_documentation' />}
      </div>
      <div className={`documentation-modal-content`}>
        <iframe title='Elisa Business IoT - Dokumentaatio' src={iframeSrc} referrerPolicy='origin' />
      </div>
    </div>
  );
};

export default Documentation;
