import ReactDOM from 'react-dom';
import React from 'react';

import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import App from './App';

import { msalConfig } from './msal-b2c/msalConfig';

import './styles/style.css';

const msalInstance = new PublicClientApplication(msalConfig as Configuration);

console.log('Elisa Business IoT - Version 1.32.15');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
