import React from 'react';

import { IconProps } from '../types/commonTypes';

const UserIcon = (props: IconProps): JSX.Element => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='24px'
    height='24px'
    viewBox='0 0 25 25'
    enableBackground='new 0 0 25 25'
    {...props}
  >
    <path
      d='M1.1,25h1c0.2-5.5,4.8-10,10.4-10s10.1,4.4,10.4,10h1c-0.2-5.1-3.8-9.3-8.5-10.6c2.7-1.1,4.6-3.8,4.6-6.9
    C20,3.4,16.6,0,12.5,0C8.4,0,5,3.4,5,7.5c0,3.1,1.9,5.8,4.6,6.9C4.9,15.7,1.3,19.9,1.1,25z M6,7.5C6,3.9,8.9,1,12.5,1S19,3.9,19,7.5
    S16.1,14,12.5,14S6,11.1,6,7.5z'
    />
  </svg>
);

export default UserIcon;
