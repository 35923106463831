import { createContext } from 'react';
import { UserInformation } from '../types/api';

export const initialUserContext: UserInformation = {
  email: '',
  isCompanyAdmin: false,
  isDeviceGroupAdmin: false,
  isInstaller: false,
  isLimitedGUI: false,
  isPermissionGroupAdmin: false,
  name: '',
  telephoneNumber: '',
  userId: '',
};

export const UserContext = createContext<UserInformation>(initialUserContext);
