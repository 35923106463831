import { useCallback, useEffect, useMemo } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { ToastContainer } from 'react-toastify';
import useSWR, { useSWRConfig } from 'swr';
import { IntlProvider } from 'react-intl';

import PageWrapperWithMsal from './components/PageWrapperWithMsal/PageWrapperWithMsal';

import { english, finnish } from './components/translations';
import lazyWithPreload from './helpers/lazyWithPreload';
import { useFetch } from './helpers/fetchUtils';
import { apiUrls } from './helpers/apiUrls';
import { options } from './helpers/common';

import { Account } from './types/common';

import { Company, PageLink, PageLinkParent, User } from './components/types/commonTypes';
import { CompanyFeatures, UserCompany, UserInformation } from './types/api';

import './toast.css';
import { useInitialPage, useLocale } from './components/common/hooks';
import { initialUserContext, UserContext } from './helpers/UserContext';
import { ProContext } from './helpers/ProContext';
import { pageLinks } from './helpers/constants';

const Home = lazyWithPreload(() => import('./pages/Home'));

const EnvironmentalDevices = lazyWithPreload(() => import('./pages/EnvironmentalDevices'));
const EnvironmentalDevice = lazyWithPreload(() => import('./pages/EnvironmentalDevice'));
const SmokeDetectors = lazyWithPreload(() => import('./pages/SmokeDetectors'));
const SmokeDetector = lazyWithPreload(() => import('./pages/SmokeDetector'));
const TrackerDevices = lazyWithPreload(() => import('./pages/TrackerDevices'));
const TrackerDevice = lazyWithPreload(() => import('./pages/TrackerDevice'));
const WaterDevices = lazyWithPreload(() => import('./pages/WaterDevices'));
const WaterDevice = lazyWithPreload(() => import('./pages/WaterDevice'));

const CustomerFields = lazyWithPreload(() => import('./pages/CustomerFields'));
const Notifications = lazyWithPreload(() => import('./pages/Notifications'));
const DeviceGroups = lazyWithPreload(() => import('./pages/DeviceGroups'));
const Control = lazyWithPreload(() => import('./pages/DeviceControl'));
const Mechanic = lazyWithPreload(() => import('./pages/Mechanic'));
const SimOrder = lazyWithPreload(() => import('./pages/SimOrder'));
const AllAlerts = lazyWithPreload(() => import('./pages/AllAlerts'));
const Alerts = lazyWithPreload(() => import('./pages/Alerts'));
const Events = lazyWithPreload(() => import('./pages/Events'));

const CompanyPage = lazyWithPreload(() => import('./pages/Company'));
const UserGroups = lazyWithPreload(() => import('./pages/UserGroups'));
const Profile = lazyWithPreload(() => import('./pages/Profile'));
const Users = lazyWithPreload(() => import('./pages/Users'));

export interface PageLinkWithOrder extends PageLink {
  order: number;
}

export interface PageLinkParentWithOrder extends PageLinkParent {
  order: number;
}

const frontpage: PageLinkWithOrder = { name: 'pages_frontpage', path: '/', order: 1 };
const deviceControl: PageLinkWithOrder = { name: 'pages_devicecontrol', path: '/laitteet', order: 3 };

const waterDevices: PageLink = { name: 'pages_watermeters', path: pageLinks.water };
const environmentalDevices: PageLink = { name: 'pages_conditionmeters', path: pageLinks.environment };
const smokedetectors: PageLink = { name: 'pages_smokedetectors', path: pageLinks.smokedetector };
const trackers: PageLink = { name: 'pages_trackers', path: pageLinks.location };

const userAdmin: PageLinkWithOrder = { name: 'pages_usercontrol', path: '/kayttajahallinta', order: 5 };
const companyAdmin: PageLinkWithOrder = { name: 'pages_company', path: '/yritys', order: 6 };
const alerts: PageLinkWithOrder = { name: 'pages_alerts', path: '/halytykset', order: 7 };

// Links for profile menu (menu on the top right side of the screen)
const profileLinks = [{ name: 'pages_profile', path: '/profiili' }];

const App = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as Account;
  const { mutate } = useSWRConfig();
  const { GET, PUT } = useFetch();

  // Save current page to session storage in case of needing to navigate there after login
  useInitialPage(window.location.pathname);

  const { data: user = initialUserContext } = useSWR<UserInformation>(
    account && `${apiUrls.user}/${account?.idTokenClaims?.extension_UserId}`,
    GET,
    options
  );
  const { data: userCompanies } = useSWR<UserCompany[]>(account ? apiUrls.company : null, GET);

  const { locale, changeLocale } = useLocale();

  // Active company is saved to session storage so it can be used to compare
  // if active company was changed in other tab/browser.
  // userCompanies are retrieved/refreshed every time tab/browser takes focus
  useEffect(() => {
    if (userCompanies) {
      const activeCompany = userCompanies.find((company) => company.isActiveCompany === true);
      const existingId = sessionStorage.getItem('active-company');
      if (activeCompany) {
        if (existingId && existingId !== activeCompany.companyId) {
          sessionStorage.removeItem('active-company');
          window.location.reload();
        } else {
          sessionStorage.setItem('active-company', activeCompany.companyId);
        }
      }
    }
  }, [userCompanies]);

  const getCompanyId = useCallback(() => {
    return userCompanies?.find((company) => company.isActiveCompany === true)?.companyId;
  }, [userCompanies]);

  const { data: companyFeatures } = useSWR<CompanyFeatures>(
    getCompanyId() && `${apiUrls.company}/features/?id=${getCompanyId()}`,
    GET,
    options
  );

  const mutateUser = useCallback(() => {
    mutate(`${apiUrls.user}/${account?.idTokenClaims?.extension_UserId}`);
  }, [account]);

  const localeData = useMemo(() => {
    if (locale === 'en') {
      return english;
    } else {
      return finnish;
    }
  }, [locale]);

  const configurationAlert = useMemo(() => {
    if (companyFeatures?.features?.configurationAlert) return companyFeatures.features.configurationAlert;
    return false;
  }, [companyFeatures]);

  const links = useMemo((): (PageLinkWithOrder | PageLinkParentWithOrder)[] => {
    const pro = companyFeatures ? companyFeatures.features.configurationAlert : false;

    const deviceAdmin: PageLinkParentWithOrder = {
      name: 'pages_control',
      children: [
        { name: 'pages_add_device', path: '/aktivointi' },
        { name: 'pages_devicegroups', path: '/laiteryhmat' },
        { name: 'pages_rights', path: '/kayttooikeudet' },
        { name: 'pages_data_fields', path: '/datakentat' },
        { name: 'pages_alertrules', path: '/halytyssaannot', disabled: !pro },
        { name: 'pages_notificationgroups', path: '/ilmoitusryhmat' },
        { name: 'pages_dataevents', path: '/tapahtumat' },
        { name: 'pages_sim_orders', path: '/sim-tilaus' },
      ],
      order: 4,
    };

    let usersLinks: (PageLinkWithOrder | PageLinkParentWithOrder)[] = [frontpage];

    // Check that company device pages and user information is loaded
    if (companyFeatures) {
      let devicePages: PageLinkParentWithOrder = { name: 'pages_devices', children: [], order: 2 };

      // Check and add all company domains
      if (companyFeatures.domains.environment) devicePages.children.push(environmentalDevices);
      if (companyFeatures.domains.smokedetector) devicePages.children.push(smokedetectors);
      if (companyFeatures.domains.water) devicePages.children.push(waterDevices);
      if (companyFeatures.domains.location) devicePages.children.push(trackers);
      if (devicePages.children.length > 0) usersLinks.push(devicePages);

      // Add /laitteet page to all users
      usersLinks.push(deviceControl);

      // If user is admin: add all views
      if (user.isCompanyAdmin) {
        usersLinks.push(deviceAdmin, userAdmin, companyAdmin, alerts);
      } else {
        if (user.isDeviceGroupAdmin) usersLinks.push(deviceAdmin);
        if (user.isPermissionGroupAdmin) usersLinks.push(userAdmin);
        usersLinks.push(alerts);
      }
    }

    return usersLinks.sort(({ order: a }, { order: b }) => a - b);
  }, [user, companyFeatures]);

  const linksWithProfileLinks = useMemo(() => [...links, ...profileLinks], [links]);

  const companyData = useMemo((): User => {
    if (userCompanies && userCompanies[0]) {
      const activeCompany = userCompanies.find((company) => company.isActiveCompany);
      if (activeCompany) {
        return {
          company: { id: activeCompany.companyId.toUpperCase(), name: activeCompany.name },
          companies: userCompanies.map((company) => ({
            id: company.companyId.toUpperCase(),
            name: company.name,
          })),
          userName: account?.idTokenClaims?.extension_UserName,
        };
      }
    }
    return {
      company: { id: '', name: '' },
      companies: [],
      userName: '',
    };
  }, [userCompanies, companyFeatures, account]);

  const changeActiveCompany = async ({ id }: Company) => {
    await PUT(`${apiUrls.userChangeCompany}?userId=${account.idTokenClaims.extension_UserId}&companyId=${id}`);
    mutate(apiUrls.company);
  };

  return (
    <UserContext.Provider value={user}>
      <ProContext.Provider value={configurationAlert}>
        <IntlProvider locale={locale} defaultLocale='fi' messages={localeData}>
          <ToastContainer />
          <PageWrapperWithMsal
            links={links}
            profileLinks={profileLinks}
            changeCompany={(e) => changeActiveCompany(e)}
            locale={locale}
            changeLocale={changeLocale}
            user={companyData}
            profilePages={[<Profile key={1} mutateUser={mutateUser} />]}
          >
            <Home
              path={'/'}
              links={linksWithProfileLinks}
              companyData={companyData}
              changeCompany={changeActiveCompany}
            />
            <WaterDevices path={pageLinks.water} />
            <WaterDevice path={`${pageLinks.water}/:imei`} />
            <EnvironmentalDevices path={pageLinks.environment} />
            <EnvironmentalDevice path={`${pageLinks.environment}/:imei`} />
            <SmokeDetectors path={pageLinks.smokedetector} />
            <SmokeDetector path={`${pageLinks.smokedetector}/:imei`} />
            <TrackerDevices path={pageLinks.location} />
            <TrackerDevice path={`${pageLinks.location}/:imei`} />
            <DeviceGroups path={'/laiteryhmat'} />
            <UserGroups path={'/kayttooikeudet'} />
            <Mechanic path={'/aktivointi'} />
            <Users path={'/kayttajahallinta'} />
            <Control path={'/laitteet'} />
            <Alerts path={'/halytyssaannot'} />
            <AllAlerts path='/halytykset' />
            <CompanyPage path={'/yritys'} />
            <SimOrder path={'/sim-tilaus'} />
            <Notifications path={'/ilmoitusryhmat'} />
            <Events path={'/tapahtumat'} activeCompany={companyData.company} />
            <CustomerFields path={'/datakentat'} />
          </PageWrapperWithMsal>
        </IntlProvider>
      </ProContext.Provider>
    </UserContext.Provider>
  );
};

export default App;
