import { locales } from '../common/hooks';

interface LanguageBlockProps {
  changeLocale(locale: locales): void;
}

const LanguageBlock = ({ changeLocale }: LanguageBlockProps) => (
  <div className='profile-language'>
    <p className='pointer' onClick={() => changeLocale('fi')}>
      FI
    </p>
    <p>|</p>
    <p className='pointer' onClick={() => changeLocale('en')}>
      ENG
    </p>
  </div>
);

export default LanguageBlock;
