import React from 'react';

import { IconProps } from '../types/commonTypes';

interface HamburgerIconProps extends IconProps {
  menuOpen: Boolean;
}

const HamburgerIcon = ({ onClick, className, menuOpen }: HamburgerIconProps): JSX.Element => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    width='25px'
    height='25px'
    viewBox='0 0 25 25'
    enableBackground='new 0 0 25 25'
    onClick={onClick}
    className={className}
  >
    <path
      d={
        menuOpen
          ? 'M21,19.6c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-7.1-7.1L5.4,21c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l7.1-7.1L4,5.4C3.6,5,3.6,4.4,4,4c0.4-0.4,1-0.4,1.4,0l7.1,7.1L19.6,4c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-7.1,7.1L21,19.6z'
          : 'M24,5.5H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h23c0.6,0,1,0.4,1,1S24.6,5.5,24,5.5z M25,20.5c0-0.6-0.4-1-1-1H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h23C24.6,21.5,25,21.1,25,20.5z M25,12.5c0-0.6-0.4-1-1-1H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h23C24.6,13.5,25,13.1,25,12.5z'
      }
    />
  </svg>
);

export default HamburgerIcon;
