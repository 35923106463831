import { AlertRuleType } from '../types/managementTypes';

export interface UnitType {
  [key: string]: string;
}

export const units: UnitType = {
  temperature: '°C',
  humidity: '%',
  charge: '%',
  voltage: 'mV',
  accuracy: 'm',
  speed: 'km/h',
  pressure: 'hPa',
  acceleration: 'G',
  minute: 'm',
  liters: 'l',
  tilt: '°',
  resendInterval: 'min',
  resendIntervalMinutes: 'min',
  silence: 'min',
  co2: 'ppm',
  voc: 'ppm',
  course: '°',
  altitude: 'm',
  radius: 'm',
};

// These are used for formatting values.
// Backend returns all values as string so "correct" types are taken from this property
export const types = {
  date: ['ts', 'created', 'timestamp', 'parserTime'],
  float: [
    'temperature',
    'charge',
    'accuracy',
    'speed',
    'pressure',
    'acceleration',
    'liters',
    'tilt',
    'silence',
    'co2',
    'voc',
    'value',
    'threshold',
    'valueParameter',
  ],
  int: [
    'humidity',
    'minute',
    'voltage',
    'altitude',
    'course',
    'state',
    'users',
    'notificationGroupMembers',
    'resendInterval',
    'resendIntervalMinutes',
  ],
  /**
   * Values that should be transformed to string
   */
  string: ['status', 'dataType', 'alertType', 'comparisonType'],
};

const statusValues = [
  'gps_unavailable',
  'gps_available',
  'base_station_location',
  '',
  '',
  '',
  '',
  '',
  '',
  'text_antenna_icon_description',
];

// Alerts & alert rules
export const alertTypes: AlertRuleType[] = ['Numerical', 'Distance', 'String', 'Boolean', 'Inactivity'];
export const dataFieldTypes = ['numerical', 'distance', 'string', 'boolean'];
export const comparisonTypes = ['GreaterThan', 'LessThan', 'Equal', 'NotEqual', 'ThresholdChanged'];

export interface StringValues {
  [key: string]: string[];
}

export const stringValues: StringValues = {
  status: statusValues,
  dataType: dataFieldTypes,
  alertType: alertTypes,
  comparisonType: comparisonTypes,
};
