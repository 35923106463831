import { LinkPrefixes } from '../components/types/managementTypes';

export const AccountProperties = {
  USERNAME: 'userName',
  USER_ID: 'userId',
};

export const MAX_EVENTS = 'maxEventCount=2500';
export const TABLE_FORMAT = 'tableFormat=true';
export const FILTER_LOCATIONS = 'filterLocations=true';

export const docURL = process.env.REACT_APP_DOCUMENTATION_URL || '';

// Prefix for links
export const pageLinks: LinkPrefixes = {
  environment: '/olosuhdemittarit',
  water: '/vesimittarit',
  location: '/paikantimet',
  smokedetector: '/palovaroittimet',
  radars: '/radars',
  onoffs: '/onoffs',
};
