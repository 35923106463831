import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Route, NavLink, Link, useLocation, Routes, Navigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useState, useMemo, useCallback, Suspense } from 'react';

import { FormattedMessage } from 'react-intl';

import HamburgerIcon from '../icons/HamburgerIcon';
import ElisaLogo from '../icons/ElisaLogo';

import UserSettings from './UserSettings';
import LoginPage from './LoginPage';
import NoMatch from './NoMatch';

import { PageLink, PageLinkParent, User, Company } from '../types/commonTypes';
import { Animations } from '../constants/constants';
import LoadingSpinner from '../common/LoadingSpinner';
import { PreloadableComponent } from '../../helpers/lazyWithPreload';
import LanguageBlock from './LanguageBlock';
import Documentation from '../common/Blocks/Documentation';
import { locales } from '../common/hooks';
import LinkRedirect from '../common/LinkRedirect';

interface PageWrapperWithMsalProps {
  children: PreloadableComponent<any>[];
  links: (PageLink | PageLinkParent)[];
  changeCompany(company: Company): void;
  locale: locales;
  changeLocale(loc: locales): void;
  user: User;
  profileLinks: PageLink[];
  profilePages: JSX.Element[];
}

const PageWrapperWithMsal = (props: PageWrapperWithMsalProps) => {
  const { children, links, changeCompany, locale, changeLocale, user, profileLinks, profilePages } = props;
  const [activePage, setActivePage] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();

  const closeMenu = () => setMenuOpen(false);

  const handleNavigation = useCallback(
    (i: number) => {
      setActivePage(i);
      closeMenu();
    },
    [activePage, menuOpen]
  );

  const mappedLinks = useMemo(
    () =>
      links.map((link, index) =>
        'children' in link ? (
          <div key={`nav-${link.name}`} className='menu-link'>
            <a key={link.name} className={activePage === index ? 'active-page menu-link-parent' : 'menu-link-parent'}>
              <FormattedMessage id={link.name} />
            </a>
            <div key={`${link.name}-child-container`} className='menu-link-children'>
              {link.children.map((child) =>
                child.disabled ? (
                  <a key={`${link.name}-${child.path}`} className='disabled-link'>
                    <FormattedMessage id={child.name} />
                  </a>
                ) : (
                  <NavLink
                    key={`${link.name}-${child.path}`}
                    to={child.path}
                    onClick={() => handleNavigation(index)}
                    onMouseOver={() => children.find((component) => component.props.path === child.path).type.preload()}
                    className={({ isActive }) => (isActive ? 'active-page' : '')}
                  >
                    <FormattedMessage id={child.name} />
                  </NavLink>
                )
              )}
            </div>
          </div>
        ) : (
          <NavLink
            key={link.path}
            to={link.path}
            onClick={() => handleNavigation(index)}
            onMouseOver={() => children.find((component) => component.props.path === link.path).type.preload()}
            className={({ isActive }) => (isActive ? 'active-page' : '')}
          >
            <FormattedMessage id={link.name} />
          </NavLink>
        )
      ),
    [links, handleNavigation, activePage]
  );

  const mappedProfileLinks = useMemo(() => {
    return profileLinks.map((link) => (
      <Link key={link.path} to={link.path} onClick={closeMenu}>
        <FormattedMessage id={link.name} />
      </Link>
    ));
  }, [profileLinks]);

  // Maps components that are not disabled to Routes
  const childComponents = useMemo((): (JSX.Element | null)[] => {
    const componentsToRender = links
      .map((link) =>
        'children' in link
          ? link.children.map((child) =>
              child?.disabled ? undefined : children.filter((e) => e.props.path.includes(child.path))
            )
          : (children.find((e) => e.props.path === link.path) as JSX.Element)
      )
      .flat(2);

    return componentsToRender.map((component) => {
      if (component !== undefined) {
        return (
          <Route
            key={component.props.path}
            path={component.props.path}
            element={<Suspense fallback={<LoadingSpinner />}>{component}</Suspense>}
          />
        );
      } else {
        return null;
      }
    });
  }, [children, links]);

  const mappedProfilePages = useMemo(
    (): JSX.Element[] =>
      profilePages.map((page, index) => (
        <Route
          key={profileLinks[index].path}
          path={profileLinks[index].path}
          element={<Suspense fallback={<LoadingSpinner />}>{page}</Suspense>}
        />
      )),
    [profilePages]
  );

  return (
    <>
      <AuthenticatedTemplate>
        <LinkRedirect />
        <div className={`page-wrapper`}>
          <header>
            <Link to='/'>
              <ElisaLogo className={'logo'} onClick={() => handleNavigation(0)} />
            </Link>
            <nav className={menuOpen ? 'menu-open' : 'menu-closed'}>
              {mappedLinks}
              <UserSettings
                changeCompany={changeCompany}
                changeLocale={changeLocale}
                user={user}
                profileLinks={mappedProfileLinks}
              />
            </nav>
            <HamburgerIcon className={'menu-button'} menuOpen={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />
          </header>
          <main>
            <TransitionGroup component={null} appear>
              <CSSTransition
                key={location.pathname}
                timeout={Animations.PAGE_TRANSITION_DURATION}
                classNames='page-transition'
                mountOnEnter
                unmountOnExit
              >
                <Routes location={location}>
                  {childComponents}
                  {mappedProfilePages}
                  <Route path={'*'} element={<NoMatch />} />
                </Routes>
              </CSSTransition>
            </TransitionGroup>
            <Documentation />
          </main>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {window.location.pathname !== '/' && <Navigate to='/' />}
        <div className={`page-wrapper`}>
          <header>
            <ElisaLogo className={'logo'} />
            <nav className={'menu-closed'}>
              <Link to={'/'} className={'active-page'}>
                <FormattedMessage id='pages_login' />
              </Link>
              <div className='profile-block'>
                <LanguageBlock changeLocale={changeLocale} />
              </div>
            </nav>
          </header>
          <main>
            <LoginPage locale={locale} changeLocale={changeLocale} />
          </main>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default PageWrapperWithMsal;
